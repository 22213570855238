<template>
  <v-app class="mx-auth">

    <img class="mx-auth-logo" src="/assets/images/logo.png" alt="logo">
    <span class="mx-auth-version">{{ options.version }}</span>

    <v-card width="500" height="100%" tile elevation="3" class="ml-auto pa-7">
      <v-card-title class="mt-7">
        <h1 class="display-1">{{ $t('auth.login') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login" ref="signUpForm" v-model="formValidity">
          <v-text-field
              v-model="form.email"
              prepend-icon="mdi-email-outline"
              type="email"
              :label="$t('auth.form.login.label.email')"
              :rules="emailRules"
              required
          ></v-text-field>
          <v-text-field
              v-model="form.password"
              prepend-icon="mdi-lock-outline"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('auth.form.login.label.password')"
              @click:append="showPassword = !showPassword"
              :rules="passwordRules"
          ></v-text-field>
        </v-form>
        <v-alert
            v-if="error"
            border="left"
            color="red"
            type="error"
        >{{ $t('auth.form.login.error') }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
            :loading="preloader"
            large color="primary"
            class="px-8"
            @click="login"
            :disabled="!formValidity"
        >
          {{ $t('auth.form.btn.login') }}
        </v-btn>
<!--        <v-btn color="warning" @click="resetValidation">Reset validation</v-btn>-->
<!--        <v-btn color="error" @click="resetForm">Reset</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      form: {
        email: '',
        password: ''
      },
      error: false,
      preloader: false,
      emailRules: [
          value => !!value || 'Email is required.',
          // value => value.indexOf('@') !== 0 || 'Email should have a username',
          // value => value.includes('@') || 'Email should include an @ symbol.',
          // value => value.indexOf('.') <= value.length - 3 || 'Email should contain a valid domain.',
          // value => value.indexOf('.') - value.indexOf('@') > 1 || 'Email should contain a valid domain extension.'
      ],
      passwordRules: [
        value => !!value || 'Email is required.',
      ],
      formValidity: false
    }
  },
  methods: {

    resetValidation() {
      this.$refs.signUpForm.resetValidation()
    },
    resetForm() {
      this.$refs.signUpForm.reset()
    },

    login() {
      this.preloader = true
      this.error = false
      this.$store.dispatch('signIn', this.form).then(() => {
        this.$router.replace({
          name: 'dashboard'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.error = true;
            break;
        }
        this.preloader = false
      })
    }
  }
}
</script>